<template>
  <div class="">
    <v-btn @click="onDrawerToggle(true)" plain small
      ><v-icon size="32">
        {{ icons.mdiFilterMenu }}
      </v-icon>
      <span class="mx-2">Aplicar Filtros </span></v-btn
    >

    <WrapperDrawer
      title="FILTROS DE PROYECTOS"
      v-model="drawerToggle"
      @close="onDrawerToggle"
    >
      <v-row>
        <v-col cols="12" xs="12" sm="12" class="my-2">
          <h3 class="subtitle-3 mb-2">General</h3>

          <FormTextField
            label="Nombre de proyecto"
            v-model="filters.s"
            class=""
            :prepend-icon="icons.mdiTextSearch"
            filled
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" class="my-2">
          <h3 class="subtitle-3 mb-2">Ubicación</h3>

          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <SelectLocationCities
                v-model="cityId"
                label="Ciudad"
                filled
                :prepend-icon="icons.mdiMapMarker"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <SelectLocationComunes
                v-model="locationId"
                label="Comunas"
                filled
                :prepend-icon="icons.mdiMapMarker"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" xs="12" sm="12" class="my-2">
          <h3 class="subtitle-3 mb-2">Categorias o rubros</h3>

          <SelectSkills
            multiple
            label="Categorias del proyecto"
            placeholder="Ej: Pintura, Construcción, Plomeria"
            v-model="categoriesId"
          />
        </v-col>

        <v-col cols="12" xs="12" sm="12" class="my-2">
          <h3 class="subtitle-3 mb-2">Condominios</h3>

          <SelectCompany
            multiple
            label="Selecciona Condominios"
            placeholder="Busca proyectos por condominios especificos"
            v-model="companyId"
          />
        </v-col>

        <v-col cols="12" xs="12" sm="12" class="my-2">
          <h3 class="subtitle-3 mb-2">Mostrar</h3>

          <v-radio-group v-model="filters.q" row>
            <v-radio label="Cotizados" value="quoted"></v-radio>
            <v-radio label="Sin cotizar" value="unquoted"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col
          cols="12"
          xs="12"
          sm="12"
          class="d-flex justify-end align-center"
        >
          <v-btn @click="onClearAllFilters" plain small class="mt-8 mx-2">
            <v-icon size="24">
              {{ icons.mdiFilterRemove }}
            </v-icon>
            Limpiar filtros</v-btn
          >

          <v-btn
            @click="onApplyFilters"
            color="primary"
            rounded
            class="mt-8 mx-2"
          >
            <v-icon size="24">
              {{ icons.mdiFilter }}
            </v-icon>
            Aplicar filtros</v-btn
          >
        </v-col>
      </v-row>
    </WrapperDrawer>
  </div>
</template>

<script>
import WrapperDrawer from "@/components/general/WrapperDrawer";
import SelectLocationCities from "@/components/general/SelectLocationCities";
import SelectLocationComunes from "@/components/general/SelectLocationComunes";
import FormTextField from "@/components/forms/TextField";
import SelectSkills from "@/components/general/SelectSkills";
import SelectCompany from "@/components/general/SelectCompany";

import {
  mdiFilterMenu,
  mdiFilterRemove,
  mdiFilter,
  mdiTextSearch,
  mdiMapMarker,
} from "@mdi/js";
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { useRouter } from "@core/utils";
import omitEmpty from "omit-empty";

const defaultFilters = {
  city_id: null,
  location_id: null,
  s: null,
  c: [],
  company: [],
  q: null,
  page: 1,
};

export default {
  props: {
    page: {
      default: 1,
    },
  },
  components: {
    WrapperDrawer,
    SelectLocationCities,
    SelectLocationComunes,
    FormTextField,
    SelectSkills,
    SelectCompany,
  },
  setup(props, { emit }) {
    const { page } = props;
    const drawerToggle = ref(false);
    const { router } = useRouter();

    const filters = ref({
      ...defaultFilters,
      ...router.currentRoute.query,
      page,
    });

    const filtered = computed(() => {
      return omitEmpty(filters.value);
    });

    const cityId = computed({
      get() {
        const { city_id } = filters.value;
        return city_id ? Number(city_id) : null;
      },
      set(value) {
        filters.value.city_id = value;
      },
    });

    const locationId = computed({
      get() {
        const { location_id } = filters.value;
        return location_id ? Number(location_id) : null;
      },
      set(value) {
        filters.value.location_id = value;
      },
    });

    const categoriesId = computed({
      get() {
        const { c } = filters.value;
        const categories = [...c];
        return categories.map((c) => Number(c));
      },
      set(value) {
        filters.value.c = [...value];
      },
    });

    const companyId = computed({
      get() {
        const { company } = filters.value;
        const companies = [...company];
        return companies.map((c) => Number(c));
      },
      set(value) {
        filters.value.company = [...value];
      },
    });

    const onDrawerToggle = (value) => {
      drawerToggle.value = value;
    };

    const onApplyFilters = () => {
      onEmitFilters();
    };

    const onClearAllFilters = () => {
      filters.value = {
        ...filters.value,
        ...defaultFilters,
      };

      onEmitFilters();
    };

    const onEmitFilters = () => {
      router.push({ query: filtered.value, replace: true }).catch(() => {});
      emit("onFilters", filtered.value);
    };

    watch(
      () => props.page,
      (value) => {
        //...
        filters.value.page = value;
        onEmitFilters();
      },
      {
        deep: true,
      }
    );

    onMounted(() => {
      filters.value = {
        ...filters.value,
        c: categoriesId.value,
        company: companyId.value,
      };

      onEmitFilters();
    });

    return {
      drawerToggle,
      filters,
      cityId,
      locationId,
      categoriesId,
      companyId,

      onDrawerToggle,
      onApplyFilters,
      onClearAllFilters,

      icons: {
        mdiFilterMenu,
        mdiFilterRemove,
        mdiFilter,
        mdiTextSearch,
        mdiMapMarker,
      },
    };
  },
};
</script>

<style></style>
