<template>
  <FormSelectField
    :value="value"
    placeholder=""
    label="Garantia del servicio o producto"
    :items="items"
    itemText="name"
    itemValue="id"
    v-bind="$attrs"
    @input="onInputSelect"
  />
</template>

<script>
import { ref } from "@vue/composition-api";

import FormSelectField from "@/components/forms/Select";
import api from "@/services";

export default {
  name: "SelectWarrantyMonth",
  props: {
    value: {
      default: null
    },
  },
  components: {
    FormSelectField,
  },

  setup(props, { emit }) {
    let warranties = [];

    for (let index = 1; index <= 48; index++) {
      warranties.push(`${ index } ${ index === 1 && 'mes' || 'meses' }`);
    }

    const items = ref(warranties);

    const onInputSelect = (data) => {
      emit('input', data)
    }

    return {
      items,

      onInputSelect
    };
  },
};
</script>

<style></style>
