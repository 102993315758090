<template>
  <div class="" v-if="project">
    <template v-if="canSendQuotationToWorker && !project.cotized">
      <v-btn rounded color="primary" @click="onDrawerToggle(true)">
        Enviar Cotización
      </v-btn>

      <small-notify class="mb-0 mt-2">
        <template #default>
          Si necesitas mas información, luego podras solicitarla en el chat
          incorporado con el condominio
        </template>
      </small-notify>
    </template>

    <v-btn rounded outlined v-if="project.cotized" @click="onSeeQuotation()">
      Ver Cotización
    </v-btn>

    <v-alert
      dense
      text
      type="error"
      class="w-100 mt-4"
      v-if="!canSendQuotationToWorker"
    >
      Se alcanzó el límite de presupuestos permitidos para este proyecto.
    </v-alert>

    <WrapperDrawer
      title="ENVIAR COTIZACIÓN"
      v-model="drawerToggle"
      @close="onDrawerToggle"
    >
      <v-form ref="addQuotationFormData" @submit.prevent="handleFormSubmit">
        <p>
          <span class="text-subtitle-1 text--primary font-weight-semibold"
            >Información de la cotización
          </span>
        </p>
        <SelectWarrantyMonth
          v-model="addFormData.warranty"
          :rules="[validators.required]"
          class="my-8"
        />

        <FormTextArea
          label="Descripción"
          v-model="addFormData.description"
          :rules="[validators.required]"
          counter
          maxlength="300"
          class="my-8"
        />

        <FormTextField
          label="Forma de pago"
          v-model="addFormData.payment_method"
          :rules="[validators.required]"
          class="my-8"
        />

        <p>
          <span class="text-subtitle-1 text--primary font-weight-semibold"
            >Adjuntos (opcional)
          </span>
        </p>

        <FormFileInput
          accept="application/pdf"
          multiple
          show-size
          counter
          :rules="[]"
          @onInputFiles="({ files }) => onInputFiles(files)"
        />

        <template v-if="isBannerInfoCompany">
          <p>
            <span class="text-subtitle-1 text--primary font-weight-semibold"
              >Adicional (opcional)
            </span>
          </p>

          <v-checkbox
            v-model="addFormData.is_receive_info_company"
            :label="`Quiero recibir los datos del cotizante`"
          ></v-checkbox>

          <v-alert
            outlined
            type="error"
            prominent
            border="left"
            v-if="addFormData.is_receive_info_company"
            class="w-100"
          >
            <p>
              <strong
                >Esta acción tiene un costo de
                {{ totalReceiveInfoCompany }} créditos</strong
              >
            </p>

            Estimado Proveedor, al seleccionar esta opción recibirá
            conjuntamente con el envió del presupuesto los datos del cotizante.
          </v-alert>
        </template>

        <v-row>
          <v-col>
            <v-btn
              rounded
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
              class="float-right mt-10"
            >
              Enviar Cotización
            </v-btn>
          </v-col>
        </v-row>

        <v-overlay :value="loadingValue"></v-overlay>
      </v-form>
    </WrapperDrawer>
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  reactive,
  getCurrentInstance,
  watch,
} from "@vue/composition-api";
import WrapperDrawer from "@/components/general/WrapperDrawer";
import FormTextField from "@/components/forms/TextField";
import FormTextArea from "@/components/forms/TextArea";
import FormFileInput from "@/components/forms/FileInput";
import SelectWarrantyMonth from "@/components/general/SelectWarrantyMonth";
import SmallNotify from "@/components/general/SmallNotify";

import { required } from "@core/utils/validation";
import { useRouter } from "@core/utils";
import { useConfigApp } from "@/composables/config-app";
import api from "@/services";

export default {
  components: {
    WrapperDrawer,
    FormTextField,
    FormTextArea,
    FormFileInput,
    SelectWarrantyMonth,
    SmallNotify,
  },
  props: {
    initProject: {
      type: Object,
      default: () => null,
    },
  },

  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { router } = useRouter();
    const {
      loadingValue,
      totalReceiveInfoCompany,
      isBannerInfoCompany,
      getConfigAppCreditCostReveiveInfoCompany,
      getConfigAppBannerInfoCompany,
    } = useConfigApp();

    const project = ref(props.initProject);
    const drawerToggle = ref(false);
    const formData = new FormData();
    const loading = ref(false);

    const addFormData = reactive({
      warranty: "",
      payment_method: "",
      description: "",
      is_receive_info_company: false,
      file: [],
    });

    const canSendQuotationToWorker = computed(() => {
      const { max_receive_quotation, total_quotations } = project.value;
      return max_receive_quotation > total_quotations;
    });

    const onDrawerToggle = (value) => {
      drawerToggle.value = value;
    };

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const onChangeFormInputs = (value, property) => {
      addFormData[property] = value;
    };

    const onInputFiles = async (files) => {
      if (!files.length) return;
      formData.delete("file");
      for (var key in files) {
        formData.append("file[]", files[key]);
      }
    };

    const onSeeQuotation = (quotationId = null) => {
      const { quotation_id } = project.value;
      const id = quotationId || quotation_id;
      if (!id) return;

      router.push({
        name: "worker.quotations.show",
        params: { id },
      });
    };

    const handleFormSubmit = async () => {
      const { id: projectId } = project.value;
      const {
        warranty,
        payment_method,
        description,
        file,
        is_receive_info_company,
      } = addFormData;
      const addQuotationFormData = vm.$refs.addQuotationFormData;

      if (!addQuotationFormData.validate() || !file) return;

      onToggleLoading();

      try {
        formData.append("warranty", warranty);
        formData.append("payment_method", payment_method);
        formData.append("description", description);
        formData.append("is_receive_info_company", is_receive_info_company);

        const { data: response } = await api.addQuotation(projectId, formData);
        const { data } = response;

        vm.$alert("Cotización enviada correctamente", null, "success");

        addQuotationFormData.reset();
        onSeeQuotation(data?.id);
      } catch (error) {
        const { data } = error.response;
        const message =
          data.message ||
          "Ocurrio un error al guardar su cotización, intente nuevamente";
        vm.$alert(message, null, "error");
      }

      onToggleLoading();
    };

    watch(
      () => addFormData.is_receive_info_company,
      (value) => {
        if (value) getConfigAppCreditCostReveiveInfoCompany();
      }
    );

    watch(
      () => props.initProject,
      (value) => {
        project.value = value;
      }
    );

    onMounted(() => {
      getConfigAppBannerInfoCompany();
    });

    return {
      project,
      drawerToggle,
      loading,
      addFormData,
      canSendQuotationToWorker,
      totalReceiveInfoCompany,
      isBannerInfoCompany,
      loadingValue,

      validators: {
        required,
      },

      onDrawerToggle,
      onToggleLoading,
      onChangeFormInputs,
      onInputFiles,
      handleFormSubmit,
      onSeeQuotation,
    };
  },
};
</script>

<style></style>
