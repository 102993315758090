<template>
  <div>
    <v-card class="mt-16 mb-8">
      <v-card-title>
        <span class="text-sm-h5 text-subtitle-1 font-weight-semibold"
          >Ofertas de Proyectos</span
        >
      </v-card-title>
      <v-card-subtitle
        >Encuentra los proyectos que mejor se adapten a tus actividades o
        rubros</v-card-subtitle
      >
    </v-card>

    <section
      class="wrapper-all-projects bg--white rounded-lg shadow-lg overflow-hidden"
    >
      <v-row>
        <v-col cols="12" xs="12" sm="12">
          <drawer-filters :page="pageCurrent" @onFilters="onApplyFilters" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="5">
          <Scroll-Div
            width="100%"
            :height="$vuetify.breakpoint.mobile ? '340px' : '500px'"
            view-class="px-2"
          >
            <div class="d-flex flex-column gap-10">
              <div class="d-flex justify-space-between align-center flex-wrap">
                <p class="text-subtitle-2 font-weight-light mb-4">
                  Resultados de la búsqueda
                </p>
                <pagination
                  v-if="projects.length"
                  :pagination="pagination"
                  @input="onInputPaginate"
                  light
                  :total-visible="5"
                  class="w-100"
                />
              </div>

              <v-card
                v-for="(project, index) in projects"
                @click="onViewProject(project)"
                :key="index"
              >
                <v-card-title>
                  <span
                    class="text-subtitle-1 font-weight-semibold primary--text"
                  >
                    {{ project.name }}
                  </span></v-card-title
                >
                <v-card-subtitle>
                  <div class="">
                    <v-icon small>{{ icons.mdiOfficeBuilding }}</v-icon>
                    {{ project.company.company }}
                  </div>

                  <div class="">
                    <v-icon small>{{ icons.mdiMapMarker }}</v-icon>
                    {{ project.city_name }} {{ project.location_name }}
                  </div>
                </v-card-subtitle>

                <v-card-text>
                  <v-chip small>{{ project.created_at }}</v-chip>
                </v-card-text>
              </v-card>

              <pagination
                v-if="projects.length"
                :pagination="pagination"
                @input="onInputPaginate"
                light
                :total-visible="5"
                class="w-100"
              />
            </div>
          </Scroll-Div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="7">
          <Scroll-Div
            width="100%"
            height="500px"
            view-class="px-2 d-none d-sm-block"
          >
            <info-project :init-project="projectActive" />
          </Scroll-Div>
        </v-col>
      </v-row>
    </section>

    <v-overlay :value="loadingProjects"></v-overlay>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  watch,
} from "@vue/composition-api";
import Pagination from "@/components/general/Pagination";
import InfoProject from "@/components/worker/project/InfoProject";

import api from "@/services";
import { useRouter, getVuetify } from "@core/utils";
import { mdiOfficeBuilding, mdiMapMarker } from "@mdi/js";
import { useProjects } from "@/composables/projects";
import DrawerFilters from "@/components/project/DrawerFilters";

export default {
  name: "WorkwerProjectPublish",
  components: {
    Pagination,
    InfoProject,
    DrawerFilters,
  },
  setup() {
    const $vuetify = getVuetify();
    const vm = getCurrentInstance().proxy;
    const { router } = useRouter();
    const { onWorkerProjectShow } = useProjects();

    const projects = ref([]);
    const loadingProjects = ref(false);
    const projectActive = ref(null);
    const cityId = ref(null);
    const pagination = ref(null);
    const filterParams = ref({});
    const pageCurrent = ref(router?.currentRoute?.query?.page || 1);

    const onApplyFilters = (filters) => {
      filterParams.value = filters;
      getProjects();
    };

    const onViewProject = (project) => {
      if ($vuetify.breakpoint.mobile) {
        onWorkerProjectShow(project);
      } else {
        projectActive.value = project;
      }
    };

    const onInputPaginate = (page) => {
      pageCurrent.value = page;
    };

    const getProjects = async () => {
      try {
        loadingProjects.value = true;
        const { data: response } = await api.getProjects({
          params: {
            ...filterParams.value,
          },
        });
        const { data, meta } = response;

        projects.value = data;
        pagination.value = { ...meta };

        if (data.length) projectActive.value = data[0];
      } catch (error) {
      } finally {
        loadingProjects.value = false;
      }
    };

    onMounted(() => {});

    return {
      projects,
      projectActive,
      pagination,
      pageCurrent,
      loadingProjects,

      icons: {
        mdiOfficeBuilding,
        mdiMapMarker,
      },

      onViewProject,
      onApplyFilters,
      onInputPaginate,
    };
  },
};
</script>

<style></style>
