import api from "@/services";
import { computed, ref, onMounted } from '@vue/composition-api'
import { useRouter } from "@core/utils";

export function useProjects() {

    const { router } = useRouter();

    const lastProjects = ref([]);
    const loading = ref(false);

    const onToggleDataTableLoading = () => {
        loading.value = !loading.value;
    };

    const getLastProjects = async (params = {}) => {

        onToggleDataTableLoading();
  
        try {
            const { data: response } = await api.getLastProjects({
                params: {
                    ...params
                },
            });
            const { data } = response;
            lastProjects.value = data;
  
        } catch (error) {}
        finally {

            onToggleDataTableLoading();
        }
  
      };

      const onWorkerProjectShow = ({ id }) => {
        router.push({ name: 'worker.projects.show', params: { id } });
      };

    onMounted(() => { })

    return {
        lastProjects,
        loading,

        getLastProjects,
        onWorkerProjectShow
    }

}