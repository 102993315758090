<template>
  <v-card v-if="project">
    <v-img
      height="250"
      :src="project.image_thumb"
      v-if="project.image_thumb"
    ></v-img>

    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4 d-flex justify-space-between flex-wrap">
          <v-chip>{{ project.created_at }}</v-chip>
          <ProjectStatus :status="project.status" />
        </div>
        <v-list-item-title
          class="text-subtitle-1 text-sm-h5 mb-1 font-weight-semibold primary--text"
        >
          {{ project.name }}
        </v-list-item-title>
        <v-list-item-subtitle
          ><v-icon small>{{ icons.mdiOfficeBuilding }}</v-icon>
          {{ project.company.company }}</v-list-item-subtitle
        >
        <v-list-item-subtitle
          ><v-icon small>{{ icons.mdiMapMarker }}</v-icon>
          {{ project.city_name }}
          {{ project.location_name }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-card-actions>
      <div class="d-flex w-100 my-2">
        <send-quotation :init-project="project" class="w-100" />
      </div>
    </v-card-actions>

    <v-divider></v-divider>

    <v-card-text>
      <TagSkills :skills="project.skills" />

      <div class="mt-5 d-flex flex-column gap-16">
        <div class="d-flex justify-space-between flex-wrap">
          <p>
            <span class="text-subtitle-1 text--primary font-weight-semibold"
              >Rango de presupuestos:
            </span>
            <span>{{ project.range_receive_quotation }}</span>
          </p>

          <p>
            <span class="text-subtitle-1 text--primary font-weight-semibold"
              >Vigencia de cotización:
            </span>
            <span>{{ project.date_dmy }}</span>
          </p>
        </div>

        <div class="d-flex flex-column">
          <p>
            <span class="text-subtitle-1 text--primary font-weight-semibold"
              >Descripción
            </span>
          </p>

          {{ project.description }}
        </div>

        <div class="d-flex flex-column">
          <p>
            <span class="text-subtitle-1 text--primary font-weight-semibold"
              >Adjuntos
            </span>
          </p>
          <TagFiles :attachments="project.attachments" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  reactive,
  getCurrentInstance,
  watch,
} from "@vue/composition-api";

import ProjectStatus from "@/components/general/projects/Status";
import TagSkills from "@/components/general/TagSkills";
import TagFiles from "@/components/general/TagFiles";
import SendQuotation from "@/components/worker/SendQuotation";

import { mdiOfficeBuilding, mdiMapMarker } from "@mdi/js";

export default {
  props: {
    initProject: {
      type: Object,
      default: () => null,
    },
  },
  components: { ProjectStatus, TagSkills, TagFiles, SendQuotation },

  setup(props) {
    const vm = getCurrentInstance().proxy;

    const project = ref(props.initProject);

    watch(
      () => props.initProject,
      (value) => {
        project.value = value;
      }
    );

    return {
      project,

      icons: {
        mdiOfficeBuilding,
        mdiMapMarker,
      },
    };
  },
};
</script>

<style></style>
