<template>
  <v-autocomplete
    :value="value"
    :items="items"
    dense
    chips
    small-chips
    itemText="company"
    itemValue="id"
    v-bind="$attrs"
    @input="onChangeSelect"></v-autocomplete>
</template>

<script>
import { ref, onMounted, watch } from "@vue/composition-api";

import api from "@/services";

export default {
  props: {
    value: {
      default: null,
    },
  },
  components: {
  },

  setup(props, { emit }) {
    const items = ref([]);

    const onChangeSelect = (data) => {
      emit("input", data);
    };

    const getUsersCompany = async () => {
      try {
        const { data } = await api.getUsersCompany();
        items.value = data;
      } catch (error) {}
    };

    onMounted(() => {
      getUsersCompany();
    });
    return {
      items,

      onChangeSelect,
    };
  },
};
</script>

<style></style>
